import React from 'react'
import Grid       from '@material-ui/core/Grid';
import LazyImage  from '../../UI/Loading/LazyImage'


export default function () {
const books = [
    {name: 'Principles', img: 'https://images-na.ssl-images-amazon.com/images/I/71FDkx5WllL.jpg'}, 
    {name: 'Becoming Dr. Q', img: 'https://images-na.ssl-images-amazon.com/images/I/51Dj1sPPF2L._SX331_BO1,204,203,200_.jpg'}, 
    {name: 'Demon Haunted', img: 'https://images-na.ssl-images-amazon.com/images/I/71VkSXM+PKL.jpg'},
    {name: 'The River of Consciousness', img: 'https://images-na.ssl-images-amazon.com/images/I/A1b6WoFSq7L.jpg'}, 
    {name: 'The Art of Power', img: 'https://m.media-amazon.com/images/I/51eWaPy86hL.jpg'}, 
    {name: 'Why We Sleep', img: 'https://images-na.ssl-images-amazon.com/images/I/8174kfNgcwL.jpg'}, 
    {name: 'The Moral Landscape', img: 'https://images-na.ssl-images-amazon.com/images/I/51AEta91taL._SX323_BO1,204,203,200_.jpg'}, 
    {name: 'Unaccountable', img: 'https://images-na.ssl-images-amazon.com/images/I/51SNCtgkqLL._SX328_BO1,204,203,200_.jpg'}, 
    {name: 'An American Sickness', img: 'https://images-na.ssl-images-amazon.com/images/I/41TvTUXYovL._SX327_BO1,204,203,200_.jpg'}, 
    {name: 'The Death of Expertise', img: 'https://images-na.ssl-images-amazon.com/images/I/71odNf1GmkL.jpg'}, 
    {name: 'Letters to a Young Contrarian', img: 'https://images-na.ssl-images-amazon.com/images/I/81ViRqr3VwL.jpg'}, 
    {name: 'I Contain Multitudes', img: 'https://images-na.ssl-images-amazon.com/images/I/51EGX0zBe1L._SX329_BO1,204,203,200_.jpg'}, 
    {name: 'Factfulness', img: 'https://images-na.ssl-images-amazon.com/images/I/818qWkHcICL.jpg'}, 
    {name: "America's Bitter Pill", img: 'https://images-na.ssl-images-amazon.com/images/I/81x464bsMIL.jpg'}, 
    {name: 'Tribe', img: 'https://images-na.ssl-images-amazon.com/images/I/91u5ybPIuAL.jpg'},
    {name: 'Zero to One', img: 'https://images-na.ssl-images-amazon.com/images/I/71Xygne8+qL.jpg'},
    {name: 'Why Evolution is True', img: 'https://images-na.ssl-images-amazon.com/images/I/71YeiRyH1SL.jpg'}, 
    {name: 'Mortality', img: 'https://images-na.ssl-images-amazon.com/images/I/41gnL1ycUXL.jpg'}, 
    {name: 'Gratitude', img: 'https://images-na.ssl-images-amazon.com/images/I/71-AhYyiTHL.jpg'},
    {name: 'Bottle of Lies', img: 'https://images-na.ssl-images-amazon.com/images/I/718eTI23bSL.jpg'},
    ]
    return (
      books.map(book => <Grid style={{marginBottom: '2rem'}}  item xs={6} sm={3}>
        <LazyImage 
            src={book.img}
            alt={book.name}
            wrapperStyle={{borderRadius: '15px', height: '195px', margin: '0 auto', width: '150px'}}
            className='IMG-Shadow'
            imageStyle={{ borderRadius: '15px', width: '150px', height: '195px'}}
          />
        </Grid>
      )
    )
}