import React from 'react'
import Grid       from '@material-ui/core/Grid';
import LazyImage  from '../../UI/Loading/LazyImage'

export default function (props) {
    const idols = [ 
    {name: 'Charles Darwin', img: 'https://www.thoughtco.com/thmb/HSM35nmKeGse9F39SwdqwuK_cF0=/3000x1687/smart/filters:no_upscale()/Charles-Darwin-3000-3x2gty-56a4890a3df78cf77282ddaf.jpg'}, 
    {name: 'Richard Feynman', img: 'https://upload.wikimedia.org/wikipedia/en/4/42/Richard_Feynman_Nobel.jpg'}, 
    {name: 'Henry David Thoreau', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Benjamin_D._Maxham_-_Henry_David_Thoreau_-_Restored_-_greyscale_-_straightened.jpg/440px-Benjamin_D._Maxham_-_Henry_David_Thoreau_-_Restored_-_greyscale_-_straightened.jpg'},
    {name: 'Thomas Jefferson', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Official_Presidential_portrait_of_Thomas_Jefferson_%28by_Rembrandt_Peale%2C_1800%29%28cropped%29.jpg/440px-Official_Presidential_portrait_of_Thomas_Jefferson_%28by_Rembrandt_Peale%2C_1800%29%28cropped%29.jpg'}, 
    {name: 'Jane Goodall', img: 'https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTcyMDYwNjI1Nzg5Mzk2MTMz/jane-goodall-english-primatologist-ethologist-and-anthropologist-with-a-chimpanzee-in-her-arms-c-1995-photo-by-apic_getty-images.jpg'}, 
    {name: 'Bruce Lee', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Bruce_Lee_1973.jpg/440px-Bruce_Lee_1973.jpg'},
    {name: 'Oliver Sacks, MD', img: 'https://i.guim.co.uk/img/media/c914de5987ab752d22b54c8fe3088f42d73019a8/0_806_5061_3036/master/5061.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=421c6f789ea5576d2ae3520d9623ce77'}, 
    {name: 'David Goggins', img: 'https://nypost.com/wp-content/uploads/sites/2/2019/01/navy-seal-goggins-ocean-1a.jpg?quality=80&strip=all&w=618&h=410&crop=1'}, 
    {name: 'Jeff Bezos', img: 'https://assets.entrepreneur.com/content/3x2/2000/20150224165308-jeff-bezos-amazon.jpeg'}, 
    {name: 'Sebastian Yunger', img: 'https://pi.tedcdn.com/r/pe.tedcdn.com/images/ted/754f2a41f233633d247b8d21612d9fca225e0e85_254x191.jpg?'},
    {name: 'Paul Graham', img: 'https://upload.wikimedia.org/wikipedia/commons/e/e3/Paulgraham_240x320.jpg'},
    {name: 'Elizabeth Rosenthal, MD', img: 'https://www.kff.org/wp-content/uploads/2016/09/elisabethrosenthal-pix.jpg'},
    {name: 'Frank Lloyd Wright', img: 'https://www.theparisreview.org/blog/wp-content/uploads/2018/03/frank-lloyd-wright.jpg'}, 
    {name: 'Buckminster Fuller', img: 'https://www.pbs.org/wnet/americanmasters/files/2001/12/BuckminsterFuller.jpg'}, 
    {name: 'Secretariat', img: 'https://cdn.britannica.com/93/162993-050-83EFD603/Secretariat-one-finishes-Ron-Turcotte-Thoroughbred-horse-1948.jpg'}, 
    {name: 'Ed Yong', img: 'https://pbs.twimg.com/profile_images/926063794912153600/962ygzzx_400x400.jpg'},
    {name: 'Tristan Harris', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR6atE-ZUIUjJVKX9RZcim1MScpPRICwNgmng&usqp=CAU'},
    {name: 'Hans Rosling, MD', img: 'https://s3.amazonaws.com/one_org_international/international/media/international/2017/02/07205937/HansRosling-7.jpg'}, 
    {name: 'Bill Gates', img: 'https://pbs.twimg.com/profile_images/988775660163252226/XpgonN0X.jpg'}, 
    {name: 'Carl Sagan', img: 'https://i0.wp.com/www.brainpickings.org/wp-content/uploads/2012/05/sagan1.jpg?fit=646%2C536&ssl=1'}, 
    ]
    const renderIdols = idols.map(idol => <Grid style={{marginBottom: '2rem', textAlign: 'center'}} item xs={6} sm={3}>
        <LazyImage 
            src={idol.img}
            alt={idol.name}
            wrapperStyle={{borderRadius: '15px', height: '150px', margin: '0 auto', width: '150px'}}
            className='IMG-Shadow'
            imageStyle={{ borderRadius: '15px', width: '150px', height: '150px'}}
          />
          <div style={{  fontWeight: 'bold'}} >
            {idol.name}
          </div>
        </Grid>
    )

    const info = <Grid style={{marginBottom: '40px'}} item xs={12} >
            <div className='Paragraph-Text'  >
                <div style={{textAlign: 'center'}} >
                    <LazyImage 
                        src={props.tree}
                        alt='thinkers'
                        wrapperStyle={{borderRadius: '15px', height: '200px', margin: '0 auto', width: '250px'}}
                        className='IMG-Shadow'
                        imageStyle={{ borderRadius: '15px', width: '250px', height: '200px'}}
                      />
                </div>
              <h5 style={{ color: '#1b047c', fontWeight: 'bold'}} >Thinkers, Scientists, Naturalists</h5>
                <p> What mostly determines who we are is <i>what</i> we learn from others.  </p>
                <p> These are some of the people, books and a horse that I have learned from the most.  </p>
            </div>
          </Grid>
    const durangoCO = {description: "", img: 'https://live.staticflickr.com/65535/50613700091_f4841f568e_b.jpg'}

    const jefferson = <Grid style={{margin: '30px 0px 30px 0px', height: '100vh', display: 'flex', alignItems: 'center', }}   item xs={12}>
        <LazyImage 
            className='IMG-Shadow'
            src={durangoCO.img}
            alt='jefferson'
            wrapperStyle={{minHeight: '200px', borderRadius: '15px'}}
            imageStyle={{height: 'auto', 
              borderRadius: '15px',  
              width: '100%', 
              objectFit: 'cover'}}
          />
         
          <h5 style={{fontWeight: 'bold', textAlign: 'center'}} >
          </h5>

        </Grid>
        

    return <React.Fragment>
        {info}
        {renderIdols}
    </React.Fragment>


}