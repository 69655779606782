import React from 'react'
import Grid       from '@material-ui/core/Grid';
import LazyImage  from '../../UI/Loading/LazyImage'

export default function (){
 const photos = [
      {description: 'Pigeon Point, Trinidad & Tobago', img: 'https://live.staticflickr.com/65535/50620515883_172afc5740_b.jpg'},
      {description: 'International Space Station streaking over the Teton mountain range', img: 'https://live.staticflickr.com/65535/50613851456_c681f97592_b.jpg'},
      {description: 'Black-crested Titmouse', img: 'https://live.staticflickr.com/65535/50611741633_2d453d0508_b.jpg'},
      {description: 'Albuquerque International Balloon Fiesta', img: 'https://live.staticflickr.com/65535/50613820211_492c58cb27_b.jpg'},
      {description: 'Moonrise over Pecos, Texas', img: 'https://live.staticflickr.com/65535/50612618627_1d0e1fce52_b.jpg'},
      {description: 'Great Blue Heron', img: 'https://live.staticflickr.com/65535/50612593012_352aedfb6f_b.jpg'},
      {description: 'The Teton Range at Jackson Lake - GTNP', img: 'https://live.staticflickr.com/65535/50612606077_d13c4eb02d_b.jpg'},
      {description: 'Least Bittern (chick)', img: 'https://live.staticflickr.com/65535/50612483371_f9d91dba2b_b.jpg'},
      {description: 'Aquarena Springs - San Marcos, Texas', img: 'https://live.staticflickr.com/65535/50613699616_b7b338089a_b.jpg'},
      {description: 'Altamira Oriole, Green Jays', img: 'https://live.staticflickr.com/65535/50590254643_8ecdc10f1b_b.jpg'},
      {description: "Home of the World's First Rodeo - Pecos, Texas", img: 'https://live.staticflickr.com/65535/50611757223_4125f24896_b.jpg'},
      {description: "Ringed Kingfisher", img: 'https://live.staticflickr.com/65535/50612601172_760151e403_b.jpg'},
      {description: "Backroads near Moab, Utah", img: 'https://live.staticflickr.com/65535/50612497746_7b95197627_b.jpg'},
      {description: "Yellow-Crowned Night Heron", img: 'https://live.staticflickr.com/65535/50612490861_84daf8308e_b.jpg'},
      {description: 'Albuquerque International Balloon Fiesta', img: 'https://live.staticflickr.com/65535/50588220472_eeca201847_b.jpg'},
      {description: 'Broad-Tailed Hummingbird (male juvenille)', img: 'https://live.staticflickr.com/65535/50591002511_afd66b96e4_b.jpg'},
      {description: 'Backroads cemetery - Rio Grande Valley, Texas', img: 'https://live.staticflickr.com/65535/50612609117_85fb4387a2_b.jpg'},
      {description: 'Green Heron (chick)', img: 'https://live.staticflickr.com/65535/50612483381_208d05d985_b.jpg'},
      {description: 'Mexican Buttonbush', img: 'https://live.staticflickr.com/65535/50612598212_90b74dea5a_b.jpg'},
      {description: 'Old Faithful - Yellowstone', img: 'https://live.staticflickr.com/65535/50612506046_defc7a4478_b.jpg'},
      {description: 'Night Heron', img: 'https://live.staticflickr.com/65535/50611739093_b845772461_b.jpg'},
      {description: 'Big Bend National Park', img: 'https://live.staticflickr.com/65535/50587200923_5117e0d9b0_b.jpg'},
      {description: 'The Key West Butterfly and Nature Conservatory', img: 'https://live.staticflickr.com/65535/50588163597_4920e892e3_b.jpg'},
      {description: 'Great Kiskadee', img: 'https://live.staticflickr.com/65535/50612593032_a67e5c8d47_b.jpg'},
      {description: 'The Grand Canyon', img: 'https://live.staticflickr.com/65535/50590893497_2e33f4bd91_b.jpg'},
      {description: 'Golden-Fronted Woodpecker', img: 'https://live.staticflickr.com/65535/50590959346_7333d42fb9_b.jpg'},
      {description: 'Abandoned church off the backroads - Rio Grande Valley, Texas', img: 'https://live.staticflickr.com/65535/50589188781_1529283584_b.jpg'},
      {description: 'Leatherback Turtle - Grand Riviere, Trinidad & Tobago', img: 'https://live.staticflickr.com/65535/50589350827_da0c8de832_b.jpg'},
      {description: 'Shooting star over Monument Valley, Utah', img: 'https://live.staticflickr.com/65535/50589359062_494c0de854_b.jpg'},
      {description: 'Ruby Throated Hummingbird', img: 'https://live.staticflickr.com/65535/50589370632_9b8aed8e64_b.jpg'},
      {description: "McDonald's Observatory - Ft. Davis, Texas", img: 'https://live.staticflickr.com/65535/50589246741_7ee42f5ba0_b.jpg'},
      {description: "Northern Cardinal", img: 'https://live.staticflickr.com/65535/50590962711_7d446af897_b.jpg'},
      {description: "Jenny Lake - Grand Teton National Park (along with my favorite quote)", img: 'https://live.staticflickr.com/65535/50588552438_a40129372d_b.jpg'},
    ]
    return (
      photos.map(photo => <Grid style={{marginBottom: '30px'}}   item xs={12}>
          <LazyImage 
            className='IMG-Shadow'
            src={photo.img}
            alt={photo.description}
            wrapperStyle={{minHeight: '200px', borderRadius: '15px'}}
            imageStyle={{height: 'auto', 
              borderRadius: '15px',  
              width: '100%', 
              objectFit: 'cover'}}
          />
          <h5 style={{fontWeight: 'bold', textAlign: 'center'}} >
            {photo.description}
          </h5>
          <hr className='Divider' />
        </Grid>
      )
    )
}