import React from 'react'
import Grid       from '@material-ui/core/Grid';
import LazyImage  from '../../UI/Loading/LazyImage'

export default function(props) {
	const FAQs = [
      {question: 'Why general surgery?',
        answer: `The craftsmanship, the immediate gratification after a successful case and the need for surgeons in rural communities are the reasons why general surgery stood out for me.  
        
        But the demanding nature of the profession is what attracted me the most.  I love the long, grueling hours and the hard work it takes to stay on top of your duties.  Surgery is a grind and life is a grind.  There will be setbacks and failures, complications and real hard times.  But I look at this reality as a way to further build character and discover more about myself.  Simply put, surgery is what makes me feel most alive. 
        `},
      {question: 'What do you anticipate being the hardest thing about intern year?', 
        answer: `Establishing a good routine and staying disciplined enough to complete all the duties and responsibilities of being an intern while maintaining a balanced and positive well-being.
        
        `},
      // {question: 'Tell me about a case that is most memorable to you.', 
      //   answer: `Laparoscopic cholecystectomy that had complications. Usually with this particular surgeon we would do the surgery in about 30-45 minutes but in this particular case the gallbladder was necrotic, friable and gangrenous.  The surgeon would touch and it would bleed immediately.  The critical view was difficult to discern and eventually a bleeding complication from a vascular injury occurred.  A routine case turned into a 4hour operation.
      //   `},
      {question: 'Where do you see yourself in 10 years?', 
        answer: `I see myself as being part of a thriving rural community serving as one of a few, or perhaps the only general surgeon.`},
      {question: 'What is a phrase that you like to repeat to yourself for motivation?', 
        answer: `"Either you do or you don't, you will or you won't!"`},
      // {question: 'What is your greatest failure?', 
        // answer: 'Software project in 2017'},
      {question: 'What is your proudest accomplishment?', 
        answer: `Coming up with a solution to tumble a .50 cal projectile after being fired from a long gun.  This occurred when I worked as a contractor for the Department of Defense.  One of our tasks was to tumble a .50 cal projectile prior to impact with a self-seal, self-heal material that we were testing.  For days we would meet and discuss ideas.  The room was filled with engineers, physicists, physical chemists, etc.  And the fact that it was my proposal that ended up solving the problem made me feel good.  

        This task was part of our Phase I DoD contract.  We were up against three other companies to win the Phase II contract, which was valued at ~$750k.  I am most proud because the solution I proposed worked and our Technical Point of Contact (TPOC) with the DoD loved the simplicity of the solution and we won the Phase II contract over the other bidders.  `},
      {question: 'What is something that you care about deeply that is not related to surgery or medicine?', 
        answer: `The consequences of digital technology and social media platforms on the well-being of societies.`},

      {question: 'What would your gravestone say?', 
        answer: `"Drive life into a corner and reduce it to its lowest terms."
        `},

      // {question: 'How do you deal with criticism and feedback?', 
        // answer: 'TBD'},
      // {question: 'What is the biggest challenge you have faced in medical school? ', 
        // answer:  `Keeping up with my academic tasks and developing software at the same time.`},
      {question: 'If you could go on a trip anywhere in the world, where would it be, and why?', 
        answer: `The Galapagos Islands.  I would love to see the place where it all began, where Charles Darwin spent his days thinking about things and taking down notes.  The beginning of our understanding of evolution as we know it.`},
      // {question: 'What are your strengths?', 
        // answer:  `Curiosity, coachable, adaptable, lateral thinking, discipline, hard work ethic, acceptance of and learning from failure`},
      // {question: 'What are your weaknesses?', 
        // answer: `Cheesecake, taking on too many projects at once and spreading myself too thin.  And cheesecake.`},
      {question: 'What are three words one of your classmates would use to describe you?', 
        answer: `Curious
        Helpful
        Determined`},
      {question: 'How will you avoid burnout?', 
        answer: `Meditation, group support/friends, diet, exercise, reading, outdoor activities, going on long nature walks as much as possible.`},
    ]
    const renderFAQs = FAQs.map((faq, i) => (
      <Grid style={{textAlign: 'left'}}  item xs={12}>
          <div className='container'>
            <h6 style={{fontWeight: 'bold', color: '#140b7c'}} >{i + 1}. {faq.question} </h6>
            <Grid  item xs={12}>
              <div style={{padding: '2rem', whiteSpace: 'pre-line'}}>
                <p style={{fontWeight: 'bold'}} >{faq.answer}</p>
              </div>
            </Grid>
            <hr className='Divider-Green' />
          </div>
        </Grid>
    ))
    const info = <Grid  item xs={12} >
            <div style={{marginBottom: '40px', textAlign: 'center'}} >
              <LazyImage 
                src='https://www.wallpaperup.com/uploads/wallpapers/2013/12/03/183179/d56fa8873b51547bcca2026dcb1d2c85-700.jpg' 
                alt='thinkers'
                wrapperStyle={{borderRadius: '15px', height: '200px', margin: '0 auto', width: '250px'}}
                className='IMG-Shadow'
                imageStyle={{ borderRadius: '15px', width: '250px', height: '200px'}}
              />
              <h5 style={{ color: '#1b047c', fontWeight: 'bold'}} >Questions and Answers</h5>
              <div className='Paragraph-Text'>
                <p> Below are my answers to some of the most commonly asked questions on the interview circuit. </p>
                <p> Some of my thoughts and responses could be expanded into several paragraphs but in the interest of time and attention, I've kept them short! :-)</p>
              </div>
            </div>
          </Grid>
    const bucky = <Grid style={{margin: '30px 0px 30px 0px', height: '100vh', display: 'flex', alignItems: 'center', }}   item xs={12}>
        <LazyImage 
            className='IMG-Shadow'
            src='https://live.staticflickr.com/65535/50621300616_7d0c5cb118_b.jpg'
            alt='bucky'
            wrapperStyle={{minHeight: '200px', borderRadius: '15px'}}
            imageStyle={{height: 'auto', 
              borderRadius: '15px',  
              width: '100%', 
              objectFit: 'cover'}}
          />

        </Grid>
    return <React.Fragment>
        {info}
        {renderFAQs}
        {bucky}
    </React.Fragment>
}