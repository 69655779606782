import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";


const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #eef1f5;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
  animation: ${loadingAnimation} 1s infinite;
`;

const StyledImage = styled.img`

`;

const LazyImage = ({ src, alt, wrapperStyle, imageStyle, className, children }) => {
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  return (
    <ImageWrapper style={{ ...wrapperStyle }}  >
      <Placeholder ref={refPlaceholder} />
      <LazyLoad>
        <StyledImage
          className={className}
          style={{ ...imageStyle }}
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={src}
          alt={alt}
        />
      </LazyLoad>
      {children}
    </ImageWrapper>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default LazyImage;