import React, { Component } from 'react';
import './App.css';
import axios from 'axios'
import me from './assets/images/chavez_jose.JPG'
import tree from './assets/images/psychedelic_tree.jpg'
import Grid       from '@material-ui/core/Grid';
import PrimarySearchAppBar     from './components/MaterialUI/AppBar/WithIcons'
import Photos     from './components/AboutMe/Photos'
import Books     from './components/AboutMe/Books'
import AboutMe     from './components/AboutMe'
import FAQ         from './components/AboutMe/FAQ'
import Influences     from './components/AboutMe/Influences';

class App extends Component {

  state = {
    feed: 'PHOTOS'
  }

  componentDidMount = async ()  => {
    try{
     const user = await axios.get('/api/tracking')
     console.log(user)
    }catch(err){
      console.log(err)
    }
  }

  renderQuote = () => (
    <div style={{height: '95vh', display: 'flex', alignItems: 'center', fontWeight: 'bold'}}  >
      <div style={{margin: '0 auto',  }} >
        <img 
          className='IMG-Shadow'
          width='95%' height='auto'
          style={{borderRadius: '15px'}} 
          src='https://www.cia.gov/++theme++contextual.agencytheme/images/mucollection/artifacts/450x360/CIA_The_Presidents_Intelligence_Check_List_3.jpg'
        />
        <div>
          <p>A few favorite lines </p>
        </div>
      </div>
    </div>
  )

  setFeedState = (feed) => {
    window.scroll(0, 0);
    this.setState((prevState) => {
      return { feed: prevState.feed === feed ? 'PHOTOS' : feed }
    })
  }
  
  renderFeed   = () => {
    switch(this.state.feed){
      case 'PHOTOS':
        return <React.Fragment>
          <AboutMe me={me} />
          <Photos />
        </React.Fragment>
      case 'FAQ': 
        return <FAQ />
      case 'INFLUENCES':
        return <React.Fragment>
          <Influences tree={tree} />
          <Books />
        </React.Fragment>
      default: 
        return null
    }
  }

  renderSignIn = () => (

    <div style={{ width: '95%', margin: '0 auto', textAlign: 'center', marginTop: '50px'}}>
      <a href="/auth/google">
        <div>
          Sign in with google
        </div>
        <span>g</span>
      </a>
    </div>
  ) 

  render() {
    const { feed } = this.state
    return (
      <div>
          <PrimarySearchAppBar setFeedState={this.setFeedState} feed={feed}  />
      <div style={{paddingTop: '125px'}} >
      <div className='container'>
      {this.state.feed === 'HOME' ? this.renderAboutMe() : null}
      <Grid container spacing={1}>
        <Grid  style={{ paddingBottom: '50px', textAlign: 'center'}} container spacing={1}>
          {this.renderFeed()}
        </Grid>
      </Grid>
      <div style={{textAlign: 'center', marginBottom: '50px'}} >
        {this.renderQuote()}
      </div>
      </div>
      </div>
      </div>
    );
  }
}

export default App;
