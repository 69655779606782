import React from 'react'
import Grid       from '@material-ui/core/Grid';
import LazyImage  from '../UI/Loading/LazyImage'

export default function(props) {
	return (
    <Grid style={{marginBottom: '40px'}} item xs={12} >
    <div style={{textAlign: 'center',display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
      <div style={{margin: '0 auto'}} >
        <div style={{textAlign: 'center'}} >
          <LazyImage 
            src={props.me}
            alt='thinkers'
            wrapperStyle={{borderRadius: '15px', width: '250px',margin: '0 auto', height: '230px'}}
            className='IMG-Shadow'
            imageStyle={{ borderRadius: '15px', width: '250px', height: '230px'}}
          />
        </div>
        <div style={{ color: '#1b047c ', fontWeight: 'bold'}} >Jose Chavez, MD Candidate</div>
        <div className='Paragraph-Text'  >
          <h5 style={{lineHeight: '2', fontWeight: 'bold', color: '#1b047c'}} > Thank you for dropping by!  </h5>
          <p> My goal in creating this is to provide a <b>quick snapshot</b> of what I am further about and what sort of things interest me. </p>
          <p> For starters, photography is one of the hobbies that I enjoy the most whether it be astrophotography, wildlife, birding or landscape.</p>
        </div>
      </div>
    </div>
    </Grid>
  )
}